<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg4 md9 sm12 pl-4 pt-6>
                    <v-layout row wrap justify-end>
                        <v-menu v-model="menuDate" :close-on-content-click="false"  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                :disabled="accountant_cumulation_vnd.ID > 0"
                                v-model="computedDateFormatted"
                                label="Ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="accountantDate" no-title @input="menuDate = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg4 md9 sm12>
                    <h-currency-input v-model="accountant_cumulation_vnd.IncomeAmount" :decimal="0" label="Khoản thu" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg4 md9 sm12>
                    <h-currency-input v-model="accountant_cumulation_vnd.OutcomeAmount" :decimal="0" label="Khoản chi" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-textarea v-model="accountant_cumulation_vnd.OutcomeReason" label="Lý do chi" class="input-group--focused"></v-textarea>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-textarea v-model="accountant_cumulation_vnd.Note" label="Ghi chú" class="input-group--focused"></v-textarea>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import configs from "@/configs";
export default {
    data() {
        return {
            menuDate: false,
            accountantDate: moment.utc(Date.now()).toISOString().substr(0, 10)
        };
    },
    computed: {
        ...mapState({
            loading: state => state.accountant_cumulation_vnd.loading,
            accountant_cumulation_vnd: state => state.accountant_cumulation_vnd.selected
        }),
        modifyId() {
            return this.$route.params.Pid;
        },
        computedDateFormatted () {
            return this.accountant_cumulation_vnd.AccountantDate > 0 ? moment.unix(this.accountant_cumulation_vnd.AccountantDate).format(configs.SHORT_DATE_FORMAT) : moment.utc(this.accountantDate).format(configs.SHORT_DATE_FORMAT);
        },
    },
    watch: {
        modifyId() {
            this.refreshData();
        },
        accountant_cumulation_vnd() {
            if(this.accountant_cumulation_vnd && this.accountant_cumulation_vnd.AccountantDate > 0) {
                this.accountantDate = moment.unix(this.accountant_cumulation_vnd.AccountantDate).toISOString().substr(0, 10)
            } 
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("accountant_cumulation_vnd/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("accountant_cumulation_vnd/setDetail", { data: {
                        ID: 0,
                        AccountantDate: 0,
                        Note: ''
                    }
                })
            }
        },
        saveData() {
            this.accountant_cumulation_vnd.AccountantDate = this.accountant_cumulation_vnd.AccountantDate > 0 ? this.accountant_cumulation_vnd.AccountantDate : moment.utc(this.accountantDate, 'YYYY-MM-DD').unix()
            this.$store.dispatch("accountant_cumulation_vnd/setDetail", {
                data: {
                    ...this.accountant_cumulation_vnd,
                    'ReCalculator': 1,
                    'up_sert': 1
                },
                id: this.accountant_cumulation_vnd.ID
            });
            window.getApp.$emit("APP_NAVIGATE", "AccountantCumulationVNDList");
        },
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>